<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.posReservationPayment"
      class="mt-2"
    />
    <ValidationObserver ref="posReservationPayment">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="addItem">
            <div class="row">
              <div class="col-lg-2">
                <ValidationProvider
                  rules="required"
                  name="Payment Type"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="paymentTypeId"
                  >
                    Payment Type
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="paymentTypeId"
                    v-model="payment.paymentTypeId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="p in paymentTypes"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.paymentType }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-lg-2">
                <MoneyInput
                  v-model="payment.amount"
                  id="amount"
                  name="Amount"
                  rules="required|between:0,9999.99"
                />
              </div>
              <div class="col-lg-8">
                <TextInput
                  name="Notes"
                  id="notes"
                  rules="max:500"
                  v-model="payment.notes"
                />
              </div>
            </div>
            <div class="row my-3">
              <div class="col-lg-12">
                <button class="btn btn-primary float-left" type="submit">
                  Add Payment
                  <i class="fas fa-plus-circle ml-1"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2 mb-2"
                  @click="resetForm"
                >
                  CLEAR
                </button>
              </div>
            </div>
          </form>
          <div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="order.payments"
              empty-text="No Reservation Fees..."
              show-empty
              stacked="xl"
              no-local-sorting
              small
              bordered
              sort-icon-left
            >
              <template v-slot:cell(paymentType)="data">
                {{ getPaymentName(data.item.paymentTypeId) }}
              </template>
              <template v-slot:cell(id)="data">
                <i
                  class="fas fa-trash-alt tashcan"
                  @click="deletePayment(data.item)"
                ></i>
              </template>
              <template
                slot="bottom-row"
                slot-scope="data"
                v-if="order.payments.length > 0"
                role="columnheader"
              >
                <b-td
                  v-for="(field, i) in data.fields"
                  :key="i"
                  class="bottom-row"
                  role="cell"
                >
                  {{ totalsItem[field.key] }}
                </b-td>
              </template>
            </b-table>
          </div>
          <div class="row my-3">
            <div class="col-lg-6">
              <button
                class="btn btn-primary float-left"
                type="button"
                @click="$emit('previous')"
              >
                Previous
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2 mb-2"
                @click="$emit('next')"
              >
                Next
              </button>
            </div>
            <div class="col-lg-6">
              <span class="total float-right"
                >BALANCE: {{ balance | formatCurrency }}</span
              >
              <span class="total float-right mr-2"
                >ORDER TOTAL: {{ orderTotal | formatCurrency }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import MoneyInput from "@/validation/MoneyInput.vue";
import moment from "moment";

export default {
  name: "AdminAddPosReservationPayments",
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    MoneyInput
  },
  props: {
    order: Object
  },
  data() {
    return {
      payment: {},
      paymentTypes: [],
      fields: [
        { key: "paymentType", label: "Payment Type", class: "colWidth" },
        {
          key: "dateCreated",
          label: "Date",
          formatter: value => {
            return moment(value, "YYYY-MM-DDThh:mm:ss.SSSZ").format(
              "MM/DD/YYYY"
            );
          },
          class: "colWidth"
        },
        { key: "notes", label: "Notes" },
        {
          key: "amount",
          label: "Amount",
          formatter: value => {
            return this.$options.filters.formatCurrency(value);
          },
          class: "colWidth"
        },
        { key: "id", label: "", class: "trashCol" }
      ],
      errors: []
    };
  },
  methods: {
    async getPaymentTypes() {
      this.$store.commit("auth/setLoading", true);
      const service = new AdminLookupService(this.tenantId);
      const response = await service.getPaymentTypes();
      if (response?.statusCode === "Success") {
        this.paymentTypes = response.data;
        this.payment.paymentTypeId = this.paymentTypes.find(
          x => x.paymentType == "Cash"
        ).id;
        this.$store.commit("auth/setLoading", false);
      } else {
        this.$store.commit("auth/setLoading", false);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    deletePayment(payment) {
      const index = this.order.payments.indexOf(payment);
      this.order.payments.splice(index, 1);
    },
    getPaymentName(id) {
      return this.paymentTypes.find(x => x.id == id)?.paymentType;
    },
    resetForm() {
      this.payment = {
        paymentTypeId: this.paymentTypes.find(x => x.paymentType == "Cash").id,
        amount: 0,
        notes: ""
      };
      this.$refs.posReservationPayment.reset();
    },
    addItem() {
      this.$refs.posReservationPayment.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(
              this.$refs.posReservationPayment.errors
            )
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.posReservationPayment.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.order.payments.push({
            ...this.payment,
            ...{
              paymentType: this.paymentTypes.find(
                x => x.id == this.payment.paymentTypeId
              )?.paymentType,
              dateCreated: new Date()
            }
          });
          this.resetForm();
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    totalsItem() {
      return {
        paymentType: "Totals",
        createdDate: null,
        notes: null,
        amount: this.$options.filters.formatCurrency(
          this.order.payments
            .map(x => +x.amount)
            .reduce((a, b) => a + (b || 0), 0)
        )
      };
    },
    reservationTotal() {
      return this.order.reservations
        .map(x => x.unitPrice * +x.quantity)
        .reduce((a, b) => a + (b || 0), 0);
    },
    fees() {
      return this.order.fees
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    orderTotal() {
      return this.reservationTotal + this.fees;
    },
    payments() {
      return this.order.payments
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    balance() {
      return this.orderTotal - this.payments;
    }
  },
  created() {
    this.getPaymentTypes();
  }
};
</script>

<style>
.bottom-row {
  font-size: medium;
  font-weight: bolder;
  background-color: #f2f2f2;
}
.colWidth {
  width: 120px !important;
}
.trashCol {
  width: 50px !important;
}
</style>
