<template>
  <div>
    <h3 class="no-print">
      {{ pointOfSaleOrderId ? "Edit" : "Add" }} POS Reservation
    </h3>
    <form action="">
      <div class="row mb-3">
        <div class="col-lg-3">
          <label for="locationId">
            Select Park
            <span class="error">*</span>
          </label>
          <select
            class="form-control form-control-lg"
            id="locationId"
            v-model="order.locationId"
            :disabled="!!order.id || order.reservations.length > 0"
          >
            <option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
              >{{ location.name }}</option
            >
          </select>
        </div>
        <div class="col-lg-3">
          <DateInput
            vid="saleDate"
            rules="required|isDate"
            name="Sale Date"
            id="saleDate"
            v-model="order.saleDate"
            :disabled="!!order.id"
          />
        </div>
      </div>
    </form>
    <b-tabs
      class-content="tab-content"
      justified
      v-model="tabIndex"
      nav-wrapper-class="no-print"
    >
      <b-tab title="Reservation Items" active>
        <AdminAddPosReservationItems
          @next="nextTab"
          @previous="previousTab"
          :order="order"
          :locations="locations"
        />
      </b-tab>
      <b-tab title="Fees" lazy>
        <AdminAddPosReservationFees
          @next="nextTab"
          @previous="previousTab"
          :order="order"
        />
      </b-tab>
      <b-tab title="Payments" lazy>
        <AdminAddPosReservationPayments
          @next="nextTab"
          @previous="previousTab"
          :order="order"
        />
      </b-tab>
      <b-tab title="Summary" lazy>
        <AdminAddPosReservationSummary
          @next="nextTab"
          @previous="previousTab"
          :order="order"
          :print="printOrder"
        />
      </b-tab>
    </b-tabs>
    <button
      type="button"
      @click="save"
      class="btn btn-primary btn-lg mr-2 float-left no-print"
    >
      Save Reservation
    </button>
    <button
      type="button"
      @click="toPos"
      class="btn btn-cancel btn-lg mr-4 float-left no-print"
    >
      Cancel
    </button>
    <label for="lastModifiedBy" class="mr-2 no-print" v-if="this.order.id"
      >Last Modified By:
      <div id="lastModifiedBy" class="normal-weight">
        {{ order.lastModifiedBy }}
      </div></label
    >
    <label for="createdBy" class="mr-2 no-print" v-if="this.order.id"
      >Created By:
      <div id="createdBy" class="normal-weight">
        {{ order.createdBy }}
      </div></label
    >
  </div>
</template>

<script>
import AdminAddPosReservationItems from "@/components/admin/AdminAddPosReservationItems.vue";
import AdminAddPosReservationFees from "@/components/admin/AdminAddPosReservationFees.vue";
import AdminAddPosReservationPayments from "@/components/admin/AdminAddPosReservationPayments.vue";
import AdminAddPosReservationSummary from "@/components/admin/AdminAddPosReservationSummary.vue";
import AdminPointOfSaleService from "@/services/admin/AdminPointOfSaleService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";

export default {
  name: "AdminAddPosReservations",
  title: "Admin - Add POS Reservations",
  mixins: [checkPermissionMixin],
  components: {
    AdminAddPosReservationItems,
    AdminAddPosReservationFees,
    AdminAddPosReservationPayments,
    AdminAddPosReservationSummary,
    DateInput
  },
  props: {
    pointOfSaleOrderId: String,
    print: String
  },
  data() {
    return {
      tabIndex: 0,
      locations: [],
      order: {
        id: null,
        locationId: null,
        reservations: [],
        fees: [],
        payments: []
      }
    };
  },
  methods: {
    nextTab() {
      this.tabIndex++;
    },
    previousTab() {
      this.tabIndex--;
    },
    toPos() {
      this.$router.push("/admin/pos-reservations").catch(() => {});
    },
    async save() {
      this.$store.commit("auth/setLoading", true);
      const service = new AdminPointOfSaleService(this.tenantId);
      const response = !this.order.id
        ? await service.addPointOfSaleOrder(this.order)
        : await service.updatePointOfSaleOrder(this.order);
      if (response?.statusCode === "Success") {
        this.$store.commit("auth/setLoading", false);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-success",
          message: `Your order has been ${
            this.order.id ? "updated." : "added."
          }`,
          layer: "admin"
        });
        this.order = {
          ...response.data,
          saleDate: this.$options.filters.formatDateAsUTCDate(
            response.data.saleDate
          )
        };
        this.$router
          .push(`/admin/edit-pos-reservations?id=${this.order.id}&p=false`)
          .catch(() => {});
      } else {
        this.$store.commit("auth/setLoading", false);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    async initialize() {
      const service = new AdminLookupService(this.tenantId);
      const locations = await service.getLocations(this.tenantId);
      this.locations = locations.filter(x =>
        this.checkLocationPermission("ReservationManagementAdd", x.id)
      );
      this.order.locationId = this.locations[0].id;
      this.order.saleDate = moment(new Date(), "MM/DD/YYYY").format("L");
    },
    async getPointOfSaleOrder() {
      if (!this.pointOfSaleOrderId) {
        return;
      }

      const service = new AdminPointOfSaleService(this.tenantId);
      const response = await service.getPointOfSaleOrder(
        this.pointOfSaleOrderId
      );
      if (response?.statusCode === "Success") {
        if (!response.data) {
          //User doesn't have access to Order
          this.$router.push("/admin/pos-reservations").catch(() => {});
        } else {
          this.order = {
            ...response.data,
            saleDate: this.$options.filters.formatDateAsUTCDate(
              response.data.saleDate
            )
          };
          if (this.printOrder) {
            this.tabIndex = 3;
          }
        }
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    printOrder() {
      return this.print === "true";
    }
  },
  async mounted() {
    await this.initialize();
    this.getPointOfSaleOrder();
  }
};
</script>

<style>
.nav-link.active {
  background-color: #103648 !important;
  color: white !important;
}
.nav-tabs .nav-link {
  background-color: #cfd7da;
  color: #103648;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 4rem;
  font-weight: bolder;
  padding: 20px 0;
  font-size: medium;
}
.tab-content {
  border: none;
}
label {
  font-weight: bold;
}
.normal-weight {
  font-weight: normal;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
  .page {
    page-break-after: always;
  }
}
</style>
