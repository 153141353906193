<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.posReservationItem"
      class="mt-2"
    />
    <ValidationObserver ref="posReservationItem">
      <div class="card">
        <div class="card-body p-5">
          <div class="mb-1 summary">
            Reservations
          </div>
          <div class="row bolder">
            <div class="col-md-3">
              <span>Park</span>
            </div>
            <div class="col-md-2">
              Product Class
            </div>
            <div class="col-md-4">
              Notes
            </div>
            <div class="col-md-1">
              Quantity
            </div>
            <div class="col-md-1">
              <span class="float-right">Price</span>
            </div>
            <div class="col-md-1">
              <span class="float-right">Total</span>
            </div>
          </div>
          <hr class="my-0" />
          <div
            class="row"
            v-for="(res, index) in order.reservations"
            :key="index"
          >
            <div class="col-md-3">
              <span
                v-if="
                  index == 0 ||
                    res.locationId != order.reservations[index - 1].locationId
                "
                >{{ getLocationName(order.locationId) }}</span
              >
            </div>
            <div class="col-md-2 ">
              {{ getProductClassName(res.productClassificationId) }}
            </div>
            <div class="col-md-4">
              {{ res.notes }}
            </div>
            <div class="col-md-1">
              {{ res.quantity }}
            </div>
            <div class="col-md-1">
              <span class="float-right">{{
                res.unitPrice | formatCurrency
              }}</span>
            </div>
            <div class="col-md-1">
              <span class="float-right">{{
                (res.unitPrice * res.quantity) | formatCurrency
              }}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="offset-lg-9"></div>
            <div class="col-lg-3 summary">
              <span class="float-left">Reservations Total: </span>
              <span class="float-right">{{
                reservationTotal | formatCurrency
              }}</span>
            </div>
          </div>
          <div class="mt-4 mb-1 summary">
            Fees
          </div>
          <div class="row bolder">
            <div class="col-md-2">
              <span>Fee Type</span>
            </div>
            <div class="col-md-8">
              Notes
            </div>
            <div class="col-md-2">
              <span class="float-right">Amount</span>
            </div>
          </div>
          <hr class="my-0" />
          <div class="row" v-for="(fee, index) in order.fees" :key="index">
            <div class="col-md-2">
              <span>{{ getProductTypeName(fee.productTypeId) }}</span>
            </div>
            <div class="col-md-8">
              {{ fee.notes }}
            </div>
            <div class="col-md-2">
              <span class="float-right">{{ fee.amount | formatCurrency }}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="offset-lg-9"></div>
            <div class="col-lg-3 summary">
              <span class="float-left">Fees Total: </span>
              <span class="float-right"> {{ fees | formatCurrency }}</span>
            </div>
          </div>
          <div class="mt-4 mb-1 summary">
            Payments
          </div>
          <div class="row bolder">
            <div class="col-md-2">
              <span>Payment Type</span>
            </div>
            <div class="col-md-8">
              Notes
            </div>
            <div class="col-md-2">
              <span class="float-right">Amount</span>
            </div>
          </div>
          <hr class="my-0" />
          <div
            class="row"
            v-for="(payment, index) in order.payments"
            :key="index"
          >
            <div class="col-md-2">
              <span>{{ getPaymentName(payment.paymentTypeId) }}</span>
            </div>
            <div class="col-md-8">
              {{ payment.notes }}
            </div>
            <div class="col-md-2">
              <span class="float-right">{{
                payment.amount | formatCurrency
              }}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="offset-lg-9"></div>
            <div class="col-lg-3 summary">
              <span class="float-left">Payments Total: </span>
              <span class="float-right"> {{ payments | formatCurrency }}</span>
            </div>
          </div>
          <div class="row">
            <div class="offset-lg-9"></div>
            <div class="col-lg-3 summary">
              <span class="float-left">Order Total: </span>
              <span class="float-right">
                {{ orderTotal | formatCurrency }}</span
              >
            </div>
          </div>
          <div class="row mb-3">
            <div class="offset-lg-9"></div>
            <div class="col-lg-3 summary">
              <span class="float-left">Remaining Balance: </span>
              <span class="float-right"> {{ balance | formatCurrency }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                name="Notes"
                rules="max:5000"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="orderNotes"
                  >Notes</label
                >
                <textarea
                  class="form-control form-control-md mb-2"
                  id="orderNotes"
                  rows="4"
                  v-model="order.notes"
                  v-bind="ariaInput"
                ></textarea>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-12">
              <button
                class="btn btn-primary float-left no-print"
                type="button"
                @click="$emit('previous')"
              >
                Previous
              </button>
              <button
                class="btn btn-secondary ml-2 no-print"
                type="button"
                @click="printScreen"
              >
                PRINT
                <i class="align-middle pb-1 fas fa-fw fa-print"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";

export default {
  name: "AdminAddPosReservationSummary",
  components: {
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert
  },
  props: {
    order: Object,
    print: Boolean
  },
  data() {
    return {
      locations: [],
      allProductClasses: [],
      productTypes: [],
      paymentTypes: [],
      errors: []
    };
  },
  methods: {
    async initialize() {
      this.$store.commit("auth/setLoading", true);
      const service = new AdminLookupService(this.tenantId);
      const productService = new AdminProductManagementService(this.tenantId);
      const locations = service.getLocations(this.tenantId);
      const productClasses = productService.getAllProductClasses();
      const productTypes = productService.getAllProductTypes();
      const paymentTypes = service.getPaymentTypes();
      await Promise.allSettled([
        locations,
        productClasses,
        productTypes,
        paymentTypes
      ]).then(async responses => {
        if (
          responses[0].value.length > 0 &&
          responses[1].value.length > 0 &&
          responses[2].value.length > 0 &&
          responses[3].value.data.length > 0
        ) {
          this.locations = responses[0].value;
          this.allProductClasses = responses[1].value;
          this.productTypes = responses[2].value.filter(
            x => x.bookingTypeId == 1
          );
          this.paymentTypes = responses[3].value.data;
          this.orderReservations();
          await this.$store.dispatch("auth/setLoading", false);
          if (this.print) {
            this.printScreen();
            this.print = false;
          }
        } else {
          this.$store.commit("auth/setLoading", false);
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    getLocationName(id) {
      return this.locations?.find(x => x.id == id)?.name;
    },
    getProductClassName(id) {
      return this.allProductClasses?.find(x => x.id == id)?.name;
    },
    getPaymentName(id) {
      return this.paymentTypes?.find(x => x.id == id)?.paymentType;
    },
    getProductTypeName(id) {
      return this.productTypes?.find(x => x.id == id)?.name;
    },
    orderReservations() {
      this.order.reservations = this.order.reservations?.sort((a, b) =>
        this.getLocationName(a.locationId) > this.getLocationName(b.locationId)
          ? 1
          : this.getLocationName(a.locationId) <
            this.getLocationName(b.locationId)
          ? -1
          : 0
      );
    },
    printScreen() {
      window.print();
      window.onafterprint = () => {
        this.$router
          .push(`/admin/edit-pos-reservations?id=${this.order.id}&p=false`)
          .catch(() => {});
      };
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservationTotal() {
      return this.order.reservations
        .map(x => x.unitPrice * +x.quantity)
        .reduce((a, b) => a + (b || 0), 0);
    },
    fees() {
      return this.order.fees
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    orderTotal() {
      return this.reservationTotal + this.fees;
    },
    payments() {
      return this.order.payments
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    balance() {
      return this.orderTotal - this.payments;
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped>
.summary {
  font-size: medium;
  font-weight: bolder;
  color: #666666;
}
.bolder {
  font-weight: bolder;
}
@media print {
  body {
    margin: 0px;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
  .card {
    width: 100% !important;
    display: block !important;
  }
  .page {
    page-break-after: always !important;
  }
}
</style>
