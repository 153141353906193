<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.posReservationFee"
      class="mt-2"
    />
    <ValidationObserver ref="posReservationFee">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="addItem">
            <div class="row">
              <div class="col-lg-2">
                <ValidationProvider
                  rules="required"
                  name="Fee Type"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="productTypeId"
                  >
                    Fee Type
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="productTypeId"
                    v-model="fee.productTypeId"
                    v-bind="ariaInput"
                  >
                    <option
                      v-for="p in productTypes"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-lg-2">
                <MoneyInput
                  v-model="fee.amount"
                  id="amount"
                  name="Amount"
                  rules="required|between:0,9999.99"
                />
              </div>
              <div class="col-lg-8">
                <TextInput
                  name="Notes"
                  id="notes"
                  rules="max:500"
                  v-model="fee.notes"
                />
              </div>
            </div>
            <div class="row my-3">
              <div class="col-lg-12">
                <button class="btn btn-primary float-left" type="submit">
                  Add Fee
                  <i class="fas fa-plus-circle ml-1"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2 mb-2"
                  @click="resetForm"
                >
                  CLEAR
                </button>
              </div>
            </div>
          </form>
          <div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="order.fees"
              empty-text="No Reservation Fees..."
              show-empty
              stacked="xl"
              no-local-sorting
              small
              bordered
              sort-icon-left
            >
              <template v-slot:cell(feeType)="data">
                {{ getFeeName(data.item.productTypeId) }}
              </template>
              <template v-slot:cell(id)="data">
                <i
                  class="fas fa-trash-alt tashcan"
                  @click="deleteFee(data.item)"
                ></i>
              </template>
              <template
                slot="bottom-row"
                slot-scope="data"
                v-if="order.fees.length > 0"
                role="columnheader"
              >
                <b-td
                  v-for="(field, i) in data.fields"
                  :key="i"
                  class="bottom-row"
                  role="cell"
                >
                  {{ totalsItem[field.key] }}
                </b-td>
              </template>
            </b-table>
          </div>
          <div class="row my-3">
            <div class="col-lg-6">
              <button
                class="btn btn-primary float-left"
                type="button"
                @click="$emit('previous')"
              >
                Previous
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2 mb-2"
                @click="$emit('next')"
              >
                Next
              </button>
            </div>
            <div class="col-lg-6">
              <span class="total float-right"
                >BALANCE: {{ balance | formatCurrency }}</span
              >
              <span class="total float-right mr-2"
                >ORDER TOTAL: {{ orderTotal | formatCurrency }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import MoneyInput from "@/validation/MoneyInput.vue";
import moment from "moment";

export default {
  name: "AdminAddPosReservationFees",
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    MoneyInput
  },
  props: {
    order: Object
  },
  data() {
    return {
      fee: {},
      locations: [],
      productClasses: [],
      productTypes: [],
      fields: [
        { key: "feeType", label: "Fee Type", class: "colWidth" },
        {
          key: "dateCreated",
          label: "Date",
          formatter: value => {
            return moment(value, "YYYY-MM-DDThh:mm:ss.SSSZ").format(
              "MM/DD/YYYY"
            );
          },
          class: "colWidth"
        },
        { key: "notes", label: "Notes" },
        {
          key: "amount",
          label: "Amount",
          formatter: value => {
            return this.$options.filters.formatCurrency(value);
          },
          class: "colWidth"
        },
        { key: "id", label: "", class: "trashCol" }
      ],
      errors: []
    };
  },
  methods: {
    async getFeeTypes() {
      this.$store.commit("auth/setLoading", true);
      const service = new AdminProductManagementService(this.tenantId);
      service.getAllProductTypes().then(response => {
        this.productTypes = response.filter(x => x.bookingTypeId == 1);
        this.fee.productTypeId = this.productTypes[0].id;
        this.$store.commit("auth/setLoading", false);
      });
    },
    deleteFee(fee) {
      const index = this.order.fees.indexOf(fee);
      this.order.fees.splice(index, 1);
    },
    getFeeName(id) {
      return this.productTypes.find(x => x.id == id)?.name;
    },
    resetForm() {
      this.fee = {
        productTypeId: this.productTypes[0].id,
        notes: "",
        amount: 0
      };
      this.$refs.posReservationFee.reset();
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DDThh:mm:ss.SSSZ").format(
        "MM/DD/YYYY hh:mm A"
      );
    },
    addItem() {
      this.$refs.posReservationFee.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.posReservationFee.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.posReservationFee.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.order.fees.push({
            ...this.fee,
            ...{
              dateCreated: new Date()
            }
          });
          this.resetForm();
          this.errors = [];
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    totalsItem() {
      return {
        feeType: "Totals",
        createdDate: null,
        notes: null,
        amount: this.$options.filters.formatCurrency(
          this.order.fees.map(x => +x.amount).reduce((a, b) => a + (b || 0), 0)
        )
      };
    },
    reservationTotal() {
      return this.order.reservations
        .map(x => x.unitPrice * +x.quantity)
        .reduce((a, b) => a + (b || 0), 0);
    },
    fees() {
      return this.order.fees
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    orderTotal() {
      return this.reservationTotal + this.fees;
    },
    payments() {
      return this.order.payments
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    balance() {
      return this.orderTotal - this.payments;
    }
  },
  created() {
    this.getFeeTypes();
  }
};
</script>

<style>
.bottom-row {
  font-size: medium;
  font-weight: bolder;
  background-color: #f2f2f2;
}
.tashcan {
  cursor: pointer;
  color: #1078a8;
}
.colWidth {
  width: 120px !important;
}
.trashCol {
  width: 50px !important;
}
</style>
