<template>
  <div>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.posReservationItem"
      class="mt-2"
    />
    <ValidationObserver ref="posReservationItem">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="addReservation">
            <div class="row">
              <div class="col-lg-3">
                <ValidationProvider
                  rules="required"
                  name="Product Class"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="productClassificationId"
                  >
                    Product Class
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="productClassificationId"
                    v-model="reservation.productClassificationId"
                    v-bind="ariaInput"
                    @change="setPrices"
                  >
                    <optgroup label="In Park">
                      <option
                        v-for="p in productClasses"
                        :key="p.id"
                        :value="p.id"
                        >{{ p.name }} - P{{
                          p.id.toString().padStart(3, "0")
                        }}
                        (${{ p.baseAmount }})</option
                      >
                    </optgroup>
                    <optgroup label="Additional">
                      <option
                        v-for="p in nonLocationClasses"
                        :key="p.id"
                        :value="p.id"
                        >{{ p.name }} - P{{
                          p.id.toString().padStart(3, "0")
                        }}
                        (${{ p.baseAmount }})</option
                      >
                    </optgroup>
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-lg-3">
                <ValidationProvider
                  rules="required"
                  name="Customer Group"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="discountClassId"
                  >
                    Customer Group
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="discountClassId"
                    v-model="reservation.discountClassId"
                    v-bind="ariaInput"
                    @change="setPrices"
                  >
                    <option
                      v-for="p in discountClasses"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.name }}</option
                    >
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-lg-3">
                <ValidationProvider
                  rules="required"
                  name="Day Type"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="dayTypeId"
                  >
                    Day Type
                    <span class="error">*</span>
                  </label>
                  <select
                    class="form-control form-control-lg"
                    id="dayTypeId"
                    v-model="reservation.dayTypeId"
                    v-bind="ariaInput"
                    @change="setUnitPrice"
                  >
                    <option v-for="p in prices" :key="p.id" :value="p.id">{{
                      p.name
                    }}</option>
                  </select>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
              <div class="col-lg-1">
                <TextInput
                  name="Quantity"
                  id="quantity"
                  rules="required|numeric"
                  v-model="reservation.quantity"
                />
              </div>
              <div class="col-lg-2">
                <MoneyInput
                  v-model="reservation.unitPrice"
                  id="unitPrice"
                  name="Unit Price"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <TextInput
                  name="Notes"
                  id="notes"
                  rules="max:500"
                  v-model="reservation.notes"
                />
              </div>
            </div>
            <div class="row my-3">
              <div class="col-lg-12">
                <button class="btn btn-primary float-left" type="submit">
                  Add Item
                  <i class="fas fa-plus-circle ml-1"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2 mb-2"
                  @click="resetForm"
                >
                  CLEAR
                </button>
                <span class="ml-3 total"
                  >TOTAL: {{ reservation.quantity > 0 ? total : "$0.00" }}</span
                >
              </div>
            </div>
          </form>
          <div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="order.reservations"
              empty-text="No Reservation Items..."
              show-empty
              stacked="xl"
              no-local-sorting
              small
              bordered
              sort-icon-left
            >
              <template v-slot:cell(productClassName)="data">
                {{ getProductClassName(data.item.productClassificationId) }}
              </template>
              <template v-slot:cell(total)="data">
                {{
                  (data.item.unitPrice * data.item.quantity) | formatCurrency
                }}
              </template>
              <template v-slot:cell(id)="data">
                <i
                  class="fas fa-trash-alt tashcan"
                  @click="deleteReservation(data.item)"
                  v-if="canDelete(data.item)"
                ></i>
              </template>
              <template
                slot="bottom-row"
                slot-scope="data"
                v-if="order.reservations.length > 0"
                role="columnheader"
              >
                <b-td
                  v-for="(field, i) in data.fields"
                  :key="i"
                  class="bottom-row"
                  role="cell"
                >
                  {{ totalsItem[field.key] }}
                </b-td>
              </template>
            </b-table>
          </div>
          <div class="row my-3">
            <div class="col-lg-6">
              <button
                class="btn btn-primary float-left"
                type="button"
                disabled
                @click="$emit('previous')"
              >
                Previous
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2 mb-2"
                @click="$emit('next')"
              >
                Next
              </button>
            </div>
            <div class="col-lg-6">
              <span class="total float-right"
                >BALANCE: {{ balance | formatCurrency }}</span
              >
              <span class="total float-right mr-2"
                >ORDER TOTAL: {{ orderTotal | formatCurrency }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminPricingManagementService from "@/services/admin/AdminPricingManagementService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import MoneyInput from "@/validation/MoneyInput.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";

export default {
  name: "AdminAddPosReservationItems",
  mixins: [checkPermissionMixin],
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
    FormErrorAlert,
    MoneyInput
  },
  props: {
    order: Object,
    locations: Array
  },
  data() {
    return {
      reservation: {},
      productClasses: [],
      discountClasses: [],
      pricingSeasons: [],
      nonLocationClasses: [],
      prices: [],
      fields: [
        { key: "locationName", label: "Park", class: "colWidth2" },
        { key: "productClassName", label: "Product Class", class: "colWidth3" },
        { key: "notes", label: "Notes" },
        { key: "quantity", label: "Quantity", class: "trashCol" },
        {
          key: "unitPrice",
          label: "Price",
          formatter: value => {
            return this.$options.filters.formatCurrency(value);
          },
          class: "colWidth"
        },
        {
          key: "total",
          label: "Total",
          class: "colWidth"
        },
        { key: "id", label: "", class: "trashCol" }
      ],
      errors: []
    };
  },
  methods: {
    async initialize() {
      this.$store.commit("auth/setLoading", true);
      const pricingService = new AdminPricingManagementService(this.tenantId);
      const productService = new AdminProductManagementService(this.tenantId);
      const seasons = pricingService.getPricingSeasons();
      const productClasses = productService.getAllProductClasses();
      const discountClasses = pricingService.getDiscountClasses();
      await Promise.allSettled([seasons, productClasses, discountClasses]).then(
        async responses => {
          if (
            responses[0].value.length > 0 &&
            responses[1].value.length > 0 &&
            responses[2].value.length > 0
          ) {
            this.pricingSeasons = responses[0].value;
            this.allProductClasses = responses[1].value;
            this.discountClasses = responses[2].value;
          } else {
            this.$store.commit("auth/setLoading", false);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      );
      this.discountClasses.unshift({ id: 0, name: "Base" });
      this.reservation.discountClassId = 0;
      await this.getProductClasses();
      this.nonLocationClasses = this.setProductClassPrices(
        this.allProductClasses
          .filter(x => !this.productClasses.map(c => c.id).includes(x.id))
          .sort((a, b) => a.id - b.id)
      );
    },
    async getProductClasses() {
      const lookupService = new AdminLookupService(this.tenantId);
      this.productClasses = await lookupService.getProductClassificationsForLocation(
        this.order.locationId
      );
      this.reservation.productClassificationId = this.productClasses[0].id;
      this.reservation.dayTypeId = 1;
      this.setPrices();
      this.productClasses = this.productClasses.sort((a, b) => a.id - b.id);
      this.productClasses = this.setProductClassPrices(this.productClasses);
      this.$store.commit("auth/setLoading", false);
    },
    setUnitPrice() {
      if (this.prices.length == 0) {
        return;
      }
      this.reservation.unitPrice = this.prices.find(
        x => x.id == this.reservation.dayTypeId
      ).price;
    },
    deleteReservation(reservation) {
      const index = this.order.reservations.indexOf(reservation);
      this.order.reservations.splice(index, 1);
    },
    canDelete() {
      return !!this.locations?.find(x => x.id == this.order.locationId);
    },
    getProductClassName(id) {
      return this.allProductClasses?.find(x => x.id == id)?.name;
    },
    async resetForm() {
      this.$refs.posReservationItem.reset();
      this.reservation = {};
      await this.getProductClasses();
    },
    setPrices() {
      if (this.pricingSeasons.length == 0) {
        return;
      }

      let prices = [];
      if (this.productClasses.length == 0) {
        this.prices = prices;
      }

      const discountClass =
        this.reservation.discountClassId !== null
          ? this.discountClasses.find(
              x => x.id === this.reservation.discountClassId
            ).name
          : "Base";
      const pricingSeason = this.pricingSeasons.find(
        x =>
          x.productClassificationId == this.reservation.productClassificationId
      );
      let pricePoint = pricingSeason?.pricePoints.find(
        x =>
          typeof x.dateBlock === "undefined" &&
          typeof x.dateBlockDay === "undefined" &&
          x.discountClassId ===
            (this.reservation.discountClassId === 0
              ? null
              : this.reservation.discountClassId)
      );
      prices.push({
        name: `${discountClass} Weekday`,
        id: 1,
        price: pricePoint?.pricePointComponents[0]?.baseAmount
      });

      pricePoint = pricingSeason?.pricePoints.find(
        x =>
          typeof x.dateBlockDay === "undefined" &&
          x.dayBlock?.name == "Weekend" &&
          x.discountClassId ===
            (this.reservation.discountClassId === 0
              ? null
              : this.reservation.discountClassId)
      );
      prices.push({
        name: `${discountClass} Weekend`,
        id: 2,
        price: pricePoint?.pricePointComponents[0]?.baseAmount
      });

      pricePoint = pricingSeason?.pricePoints.find(
        x =>
          typeof x.dayBlock === "undefined" &&
          x.dateBlockDay?.name == "Memorial Day" &&
          x.discountClassId ===
            (this.reservation.discountClassId === 0
              ? null
              : this.reservation.discountClassId)
      );
      prices.push({
        name: `${discountClass} Holiday Weekday`,
        id: 3,
        price: pricePoint?.pricePointComponents[0]?.baseAmount
      });

      pricePoint = pricingSeason?.pricePoints.find(
        x =>
          x.dateBlockDay?.name == "Memorial Day" &&
          x.dayBlock?.name == "Weekend" &&
          x.discountClassId ===
            (this.reservation.discountClassId === 0
              ? null
              : this.reservation.discountClassId)
      );
      prices.push({
        name: `${discountClass} Holiday Weekend`,
        id: 4,
        price: pricePoint?.pricePointComponents[0]?.baseAmount
      });
      this.prices = prices;
      this.setUnitPrice();
    },
    setProductClassPrices(classes) {
      if (this.pricingSeasons.length == 0) {
        return classes;
      }

      classes.forEach(c => {
        let pricingSeason = this.pricingSeasons.find(
          x => x.productClassificationId === c.id
        );
        let pricePoint = pricingSeason?.pricePoints.find(
          x =>
            typeof x.dateBlock === "undefined" &&
            typeof x.dateBlockDay === "undefined" &&
            x.discountClassId === null
        );
        c.baseAmount = pricePoint?.pricePointComponents[0]?.baseAmount;
      });
      return classes;
    },
    addReservation() {
      const self = this;
      this.$refs.posReservationItem.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.posReservationItem.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.posReservationItem.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          self.order.reservations.push({
            discountClassId:
              this.reservation.discountClassId == 0
                ? null
                : this.reservation.discountClassId,
            ...this.reservation,
            ...{
              locationName: this.locations.find(
                x => x.id == this.order.locationId
              )?.name,
              productClassName: this.productClasses.find(
                x => x.id == this.reservation.productClassificationId
              )?.name
            }
          });
          self.errors = [];
          await self.resetForm();
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    totalsItem() {
      return {
        locationName: "Totals",
        productClassName: null,
        quantity: this.order.reservations
          .map(x => +x.quantity)
          .reduce((a, b) => a + (b || 0), 0),
        unitPrice: null,
        total: this.$options.filters.formatCurrency(
          this.order.reservations
            .map(x => +(x.unitPrice * x.quantity))
            .reduce((a, b) => a + (b || 0), 0)
        )
      };
    },
    total() {
      return this.$options.filters.formatCurrency(
        this.reservation.unitPrice * +this.reservation.quantity
      );
    },
    reservationTotal() {
      return this.order.reservations
        .map(x => x.unitPrice * +x.quantity)
        .reduce((a, b) => a + (b || 0), 0);
    },
    fees() {
      return this.order.fees
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    orderTotal() {
      return this.reservationTotal + this.fees;
    },
    payments() {
      return this.order.payments
        .map(x => +x.amount)
        .reduce((a, b) => a + (b || 0), 0);
    },
    balance() {
      return this.orderTotal - this.payments;
    }
  },
  watch: {
    "order.locationId": {
      handler(newValue) {
        if (newValue) {
          this.getProductClasses();
        }
      }
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style>
.bottom-row {
  font-size: medium;
  font-weight: bolder;
  background-color: #f2f2f2;
}
.total {
  font-size: medium;
  font-weight: bolder;
  color: #666666;
}
.tashcan {
  cursor: pointer;
  color: #1078a8;
}
.colWidth {
  width: 120px !important;
}
.colWidth2 {
  width: 300px !important;
}
.colWidth3 {
  width: 150px !important;
}
.trashCol {
  width: 50px !important;
}
</style>
